import { template as template_edf89828ad16475bbbad86c176219e0a } from "@ember/template-compiler";
import { notEq } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
const FKControlConditionalContentItem = template_edf89828ad16475bbbad86c176219e0a(`
  <div
    class={{concatClass
      "form-kit__conditional-display-content"
      (if (notEq @name @activeName) "hidden")
    }}
  >
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlConditionalContentItem;
