import { template as template_822c1828206240feba80c0de24dd65b5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_822c1828206240feba80c0de24dd65b5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
