import { template as template_b7627cd8044342258a629f75680dc5a1 } from "@ember/template-compiler";
const FKText = template_b7627cd8044342258a629f75680dc5a1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
